import './app.scss'

import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import AppLayout from './components/layouts/app-layout/app-layout'

const App = () => {
  return (
    <div className='App'>
      <BrowserRouter>
        <AppLayout>
          <Routes/>
        </AppLayout>
      </BrowserRouter>
    </div>
  )
}

export default App
