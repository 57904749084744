import './approach.scss'

import { FC } from 'react'
import ApproachImg from '../../assets/imgs/approach/approach.png'

const Approach: FC = () => {
  return (
    <div id='approach'>
      <h1>Approach</h1>
      <div id='approach-content'>
        <img src={ApproachImg} alt='approach' />
        <p>
          In today’s aerospace market contractors tend to operate in one of two
          regimens
        </p>
        <ul>
          <li>
            High Reliability – Low risk, high cost, extended schedules, highly
            reliable
          </li>
          <li>
            Low Cost – High risk, low cost, abbreviated schedules, reduced
            reliability
          </li>
        </ul>
        <p>
          Our experience will help you tailor processes to align with your
          threshold for risk, reliability goals, allocated budget, and desired
          time to market
          <br />
          The result is a set of project management and systems engineering
          processes that apply appropriate rigor but keep your project on
          schedule, and within budget
        </p>
      </div>
    </div>
  )
}

export default Approach
