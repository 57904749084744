import './animate-on-visible.scss'
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'

const OFFSET = 200

export const AnimateOnVisible: FC<PropsWithChildren> = ({ children }) => {
  const container = useRef<HTMLDivElement | null>(null)
  const [scrollBody, setScrollBody] = useState<Element | undefined>(undefined)
  const [scrollListenerAttached, setScrollListenerAttached] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setScrollBody(document.getElementsByClassName('App')[0])

    return () => {
      scrollBody?.removeEventListener('scroll', () => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleScroll(new Event('scroll'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollBody])

  const handleScroll = (e: Event) => {
    if (!isVisible) {
      const bottomCheck =
        (scrollBody?.clientHeight ?? 0) + (scrollBody?.scrollTop ?? 0) >
        (container.current?.offsetTop ?? 0) + OFFSET

      if (bottomCheck) {
        setIsVisible(true)
      }
    }
  }

  useEffect(() => {
    if (container && scrollBody && !scrollListenerAttached) {
      scrollBody?.addEventListener('scroll', (e) => handleScroll(e))
      setScrollListenerAttached(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, isVisible, scrollBody, scrollListenerAttached])

  return (
    <div
      className='animate-on-visible'
      ref={container}
      style={{ animation: isVisible ? 'fade-in 1s ease forwards' : '' }}
    >
      {children}
    </div>
  )
}
