import './home-hero.scss'

import React, { useRef, useMemo } from 'react'
import * as THREE from 'three'
import { useRefResize } from '../../../hooks/use-ref-resize'
import map from '../../../assets/imgs/world.svg'

const HomeHero: React.FC = () => {
  const heroRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef(null)
  const dimensions = useRefResize(containerRef)
  const scene = useMemo(() => new THREE.Scene(), [])
  const renderer = useMemo(() => new THREE.WebGLRenderer({ alpha: true }), [])
  /* eslint-disable-next-line react-hooks/exhaustive-deps*/
  const camera = useMemo(
    () =>
      new THREE.PerspectiveCamera(
        45,
        (dimensions.width ?? 0) / (dimensions.height ?? 0),
        0.1,
        10000
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  React.useEffect(() => {
    if (containerRef.current) {
      // scene.background = new THREE.Color(0x000)
      camera.position.set(0, 0, 500)

      const radians = (23.4 * Math.PI) / 180
      const globe = new THREE.Group()
      const loader = new THREE.TextureLoader()
      loader.load(map, (texture) => {
        const sphere = new THREE.SphereGeometry(200, 50, 50)
        const material = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true,
        })
        const mesh = new THREE.Mesh(sphere, material)
        mesh.material.side = THREE.DoubleSide
        mesh.geometry.applyMatrix4(new THREE.Matrix4().makeRotationZ(-radians))
        mesh.add(
          new THREE.LineSegments(
            sphere,
            new THREE.LineBasicMaterial({
              color: 'lightblue',
              transparent: true,
              opacity: 0.035,
            })
          )
        )
        globe.add(mesh)
      })

      globe.position.x = 200
      // globe.position.y = -150
      globe.position.z = -100
      const globeAxis = new THREE.Vector3(
        Math.sin(radians),
        Math.cos(radians),
        0
      ).normalize()
      scene.add(globe)

      const container = containerRef.current as HTMLElement
      if (container.childNodes.length === 0) {
        container.appendChild(renderer.domElement)
      }

      const animate = () => {
        requestAnimationFrame(animate)
        globe.rotateOnAxis(globeAxis, 0.001)
        renderer.render(scene, camera)
      }

      animate()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [containerRef])

  React.useEffect(() => {
    if (dimensions) {
      camera.aspect = (dimensions.width ?? 0) / (dimensions.height ?? 0)
      camera.updateProjectionMatrix()

      renderer.setSize(dimensions.width ?? 0, dimensions.height ?? 0)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [dimensions])

  const scrollDown = () => {
    if (heroRef.current) {
      document.getElementsByClassName('App')[0].scrollBy({
        top:
          heroRef.current.clientHeight -
          document.getElementsByClassName('App')[0].scrollTop,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <>
      <div id='home-hero' ref={heroRef}>
        <div id='home-hero-text'>
          <h1>
            Getting your ideas
            <br />
            off the ground
            <br />
          </h1>
          <h1>
            and into...
            <br />
          </h1>
          <h1>
            <span className='space-text'>SPACE</span>
          </h1>
        </div>
        <div id='home-hero-canvas-container' ref={containerRef} />
        <div id='home-hero-action-container'>
          <button id='home-hero-action' onClick={scrollDown}>
            <span>Explore</span>
            <svg width='64' height='16'>
              <path
                d='M2,2 L32,14 L62,2'
                fill='none'
                stroke='white'
                strokeWidth='2'
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  )
}
export default HomeHero
