import './services.scss'
import { FC } from 'react'
import MissionDesignEngineeringIcon from '../../assets/imgs/services/mission-design-engineering.jpg'

const Services: FC = () => {
  return (
    <div id='services'>
      <div id='services-container'>
        <img src={MissionDesignEngineeringIcon} alt='space side bar icon' />
        <div id='services-content-container'>
          <h1>Services</h1>
          <div className='service-card-container'>
            <div className='service-card'>
              <div className='service-card-content'>
                <h2>Mission Design and Engineering</h2>
                <ul>
                  <li>Complete mission and system design</li>
                  <li>Product-oriented systems engineering processes</li>
                  <li>Manage requirements, cost, schedule</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='service-card-container'>
            <div className='service-card'>
              <div className='service-card-content'>
                <h2>Systems Engineering</h2>
                <ul>
                  <li>Mission engineering</li>
                  <li>
                    Enterprise, space, and ground architecture definitions
                  </li>
                  <li>Requirements/interface management</li>
                  <li>System integration and verification</li>
                  <li>Technical process definition</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='service-card-container'>
            <div className='service-card'>
              <div className='service-card-content'>
                <h2>Business Development and Marketing</h2>
                <ul>
                  <li>Mission concept development</li>
                  <li>Technical concept development</li>
                  <li>Aligning commercial products with government markets</li>
                  <li>Product marketing</li>
                  <li>Proposal management and writing</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='service-card-container'>
            <div className='service-card'>
              <div className='service-card-content'>
                <h2>Contract Technical Support</h2>
                <ul>
                  <li>Program and technical management</li>
                  <li>Technical surge support</li>
                  <li>
                    Broad network of contractors and subject matter experts
                  </li>
                  <li>full-time, part-time/long-term, short-term</li>
                  <li>Export compliance</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
