import { FC } from 'react'
import './customers.scss'
import { importAllImages } from '../../utils'

// Band across website with icons infinitely scrolling sideways
export const Customers: FC = () => {
  const customers = importAllImages(
    require.context('../../assets/imgs/customers', false, /\.(png|jpe?g)$/)
  )

  return (
    <>
      <div id='customers'>
        {/* <h2>Customers</h2> */}
        <div id='customers-bar'>
          <div id='customers-photo-banner'>
            {customers.map((customer, idx) => (
              <img
                src={customer}
                alt={`customer-${idx}-1`}
                key={`customer-${idx}-1`}
              />
            ))}
            {customers.map((customer, idx) => (
              <img
                src={customer}
                alt={`customer-${idx}-2`}
                key={`customer-${idx}-2`}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
