import './footer.scss'

const Footer: React.FC = () => {
  return (
    <footer>
      <div id="footer-content">
        <p>Site created by:</p>
        <p>Daniel Hawes | hawesdb@gmail.com</p>
      </div>
    </footer>
  )
}

export default Footer