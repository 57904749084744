import './home.scss'

import { FC } from 'react'
import HomeHero from '../../components/heroes/home-hero/home-hero'
import { AnimateOnVisible, Divider } from '../../components/utils'
import { Customers } from '../../components/customers/customers'

const Home: FC = () => {
  return (
    <>
      <HomeHero />
      <Divider />
      <div id='home-about'>
        <h1>
          <AnimateOnVisible>
            Camber Research (Camber) helps companies with emerging technologies
            develop and market their products to government and commercial
            customers. Founded in 2014, Camber has delivered key services in
            contract engineering, program management, and business development
            to support the development of strategic products.
          </AnimateOnVisible>
        </h1>
        <ul id='home-about-services'>
          <li className='home-about-service'>
            <p>
              Supporting aerospace product development through contract
              engineering services
            </p>
          </li>
          <li className='home-about-service'>
            <p>
              Marketing innovative commercial technologies to solve technical
              challenges within the government space enterprise
            </p>
          </li>
          <li className='home-about-service'>
            <p>Updating heritage products for emerging markets</p>
          </li>
          <li className='home-about-service'>
            <p>
              Providing export compliance services for customers working with
              international partners
            </p>
          </li>
        </ul>
      </div>
      <Customers />
    </>
  )
}

export default Home
