import { FC } from 'react'
import './divider.scss'

export const Divider: FC = () => {
  return (
    <div className='site-divider-container'>
      <div className='site-divider' />
      <div className='site-divider' />
    </div>
  )
}
