import React, { useEffect, useState } from 'react'

type refDimensions = {
  width: number | undefined
  height: number | undefined
}

export const useRefResize = (ref: React.MutableRefObject<null>) => {
  const [dimensions, setDimensions] = useState<refDimensions>({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    resize()
  /* eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [ref])

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  /* eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [])

  const resize = () => {
    if (ref.current) {
      const { current } = ref
      const boundingRect = (current as HTMLElement).getBoundingClientRect()
      const { width, height } = boundingRect
      setDimensions({ width: Math.round(width), height: Math.round(height) })
    }
  }

  return dimensions
}
