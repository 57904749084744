import './about.scss'

import { FC } from 'react'

const About: FC = () => {
  return (
    <div id='about'>
      <h1>About</h1>
      <div id='about-content'>
        <p>
          Camber Research was founded in 2014 with the charter of helping
          start-up companies transition their products to the government sector.
          We work with our clients to evolve their commercial technologies into
          solutions to unique government challenges while enabling them to
          maintain focus on their commercial objectives.
          <br />
          <br />
          Our team of highly qualified engineers and Subject Matter Experts
          (SMEs) has decades of experience in civil and defense sectors
          including NASA, DARPA, and the Intelligence Community (IC). We combine
          our in-depth knowledge of agency roadmaps with our product development
          expertise to transform commercial technologies into government
          solutions. We can also tap into our broad network of industry
          professionals to add specialized skills—providing the capability to
          rapidly mature emerging technologies.
          <br />
          <br />
          As a contract technical services and consulting company, Camber
          provides multiple services to support long-term development needs, as
          well as offers surge capability to achieve critical milestones. These
          services include the following, among others:
        </p>
        <ul>
          <li>Project Management (PM)</li>
          <li>Systems Engineering, Integration, and Test (SEIT)</li>
          <li>Business Development (BD)</li>
          <li>Mission Operations</li>
          <li>Contract Engineering</li>
        </ul>
      </div>
    </div>
  )
}

export default About
