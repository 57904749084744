import './app-layout.scss'
import MainMenu from '../../menus/main-menu/main-menu'
import Footer from '../../footer/footer'

type AppLayoutProps = {
  children: any
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  return (
    <>
      <div className='app-container'>
        <MainMenu />
        <main className='app-content'>
          {children}
        </main>
        <Footer />
      </div>
    </>
  )
}

export default AppLayout
