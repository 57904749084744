import React from 'react'
import './site-blocker.scss'

const SiteBlocker: React.FC = () => {
  const siteBlockerRef = React.useRef(null)

  React.useEffect(() => {
    if (siteBlockerRef.current) {
      const SiteBlocker = siteBlockerRef.current as HTMLElement
      SiteBlocker.style.opacity = '1'
      console.log('HERE')

      return () => {
        SiteBlocker.style.opacity = '0'
      }
    }
  }, [])

  return (
    <div className="site-blocker" ref={siteBlockerRef}></div>
  )
}

export default SiteBlocker